import $axios from '@/plugins/axios'

const model = 'my-hotel-reservations'
export default {
  state: {
    reservstions: [],
    reservstionsList: [],
    reservstion: {}
  },
  getters: {
    reservstions: state => state.reservstions,
    reservstionsList: state => state.reservstionsList,
    reservstion: state => state.reservstion
  },
  actions: {
    async getReservations ({ commit }, date) {
      const response = await $axios.get(`/${model}?take=1000&date=${date}`)
      commit('SET_RESERVATIONS', response.data)
    },
    async getReservationInfo ({ commit }, id) {
      const response = await $axios.get(`/reservation-info/${id}`)
      commit('SET_RESERVATION', response.data)
    },
    async getReservationList ({ commit }, filterData = {
      take: 10,
      skip: 0,
      reservation_no: '',
      full_name: '',
      email: '',
      phone: '',
      created_at: ''
    }) {
      const response = await $axios.get(`/reservation-info?include=client&filter[reservation_no]=${filterData.reservation_no}&filter[full_name]=${filterData.full_name}&filter[email]=${filterData.email}&filter[phone]=${filterData.phone}&filter[created_at]=${filterData.created_at}`)
      commit('SET_RESERVATIONS_LIST', response.data)
    }
  },
  mutations: {
    SET_RESERVATIONS (state, data) {
      state.reservstions = data
    },
    SET_RESERVATIONS_LIST (state, data) {
      state.reservstionsList = data
    },
    SET_RESERVATION (state, data) {
      state.reservstion = data
    }
  }
}
