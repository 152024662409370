import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './config/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import { mapGetters, mapActions, mapMutations } from 'vuex'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDcH70GTe-9uRhq-i8B7xWoybbPf8o-4OE',
    libraries: 'places'
  }
})

global.Raphael = Raphael
const mixin = {
  computed: {
    ...mapGetters(['reviews', 'comment', 'likes', 'reservationsStatistic', 'loaderState', 'discounts', 'userData', 'reservstions', 'reservstion', 'governorates', 'cities', 'services', 'features', 'landmarks', 'floors', 'permissions', 'roles', 'users', 'hotels', 'hotel', 'hotelImgs', 'rooms', 'room', 'room_images', 'priceList', 'roomPrice']),
    userInfo () {
      return JSON.parse(localStorage.getItem('booking-hotel-info')).userAuth || false
    }
  },
  methods: {
    ...mapMutations(['changeLoaderStatus']),
    ...mapActions([
      'hotelReviews',
      'hotelReservations',
      'hotelComment',
      'hotelLikes',
      'loginUser',
      'logoutUser',
      'registerHotel',
      'getHotels',
      'getHotel',
      'getHotelImg',
      'addHotelImg',
      'removeHotelImg',
      'addHotels',
      'updateHotels',
      'removeHotels',

      'getGovernorates',
      'addGovernorates',
      'updateGovernorates',
      'removeGovernorates',

      'getCities',
      'addCities',
      'updateCities',
      'removeCities',

      'getServices',
      'getServicesRoom',
      'addHotelServices',
      'updateServices',
      'removeServices',
      'addRoomServices',

      'getFeatures',
      'addHotelFeatures',
      'updateFeatures',
      'removeFeatures',

      'getLandmarks',
      'addLandmarks',
      'updateLandmarks',
      'removeLandmarks',
      'getFloors',

      'getPermissions',
      'addPermissions',
      'updatePermissions',
      'removePermissions',
      'assignPermissionsToUsers',

      'getRoles',
      'addRoles',
      'updateRoles',
      'removeRoles',

      'getDiscounts',
      'addDiscounts',
      'updateDiscounts',
      'removeDiscounts',

      'getUsers',
      'addUsers',
      'updateUsers',
      'removeUsers',

      'getRooms',
      'getRoom',
      'addRooms',
      'updateRooms',
      'removeRooms',

      'getRoomImage',
      'setRoomImage',
      'removeRoomImage',
      'ChangeAvailabelRoom',

      'getPriceList',
      'getRoomPriceList',
      'sortRoomPrice',
      'updateRoomPrice',
      'getReservations'
    ]),
    confermMsg (action) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          action()
        }
      })
    },
    resetObject (target) {
      for (var key in target) {
        if (typeof target[key] === 'number') {
          target[key] = 0
        } else if (typeof target[key] === 'string') {
          target[key] = ''
        } else if (typeof target[key] === 'boolean') {
          target[key] = false
        } else if (Array.isArray(target[key])) {
          target[key] = []
        } else {
          target[key] = null
        }
      }
      return target
    },
    returnData (obg) {
      var data = new FormData()

      for (var key in obg) {
        if (obg[key] !== '' && obg[key] !== null) {
          data.append(key, obg[key])
        }
      }

      return data
    }
  }
}
Vue.mixin(mixin)

Vue.use(AlgoliaComponents)

Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
